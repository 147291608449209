/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var screen = $(window).width();

        $('.testimonial-bubble').slick({
            slidesToShow: 1,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            prevArrow: '<i class="fa fa-arrow-left prev" aria-hidden="true"></i>',
            nextArrow: '<i class="fa fa-arrow-right next" aria-hidden="true"></i>'
        });

        $(document).ready(function () {

            $("#newsletter-select").change(function(){
                $(this).find("option:selected").each(function(){
                    if($(this).attr("value")=="regional"){
        				$(".newsletter_forms").not(".regional_form").hide();
                        $(".regional_form").show();
                    }
                    else if($(this).attr("value")=="ueberregional"){
        				$(".newsletter_forms").not(".ueberregional_form").hide();
                        $(".ueberregional_form").show();
                    }
                    else if($(this).attr("value")=="tagen-feiern"){
        				$(".newsletter_forms").not(".tagen_feiern_form").hide();
                        $(".tagen_feiern_form").show();
                    }
                    else{
                        $(".newsletter_forms").hide();
                    }
                });
            }).change();

            $('a').each(function() {
                var a = new RegExp('/' + window.location.host + '/');
                if (!a.test(this.href)) {
                    var b = new RegExp('(tel:|mailto:|javascript:)');
                    if (!b.test(this.href)) {
                        if ($(this).attr('target') !== '_self') {
                            $(this).attr("target","_blank");
                        }
                    }
                }
             });
            if (screen > 1500) {
                $('#booking').removeClass('closed');
                $('#booking').addClass('opened');
            } else {
                $('#booking').removeClass('opened');
                $('#booking').addClass('closed');
            }

            $('.toggle_booking').click(function () {
                if ($("#booking").hasClass("opened")) {
                    $('#booking').removeClass('opened');
                    $('#booking').addClass('closed');
                } else {
                    $('#booking').removeClass('closed');
                    $('#booking').addClass('opened');
                }

            });

            $('.owl-carousel').owlCarousel({
                items : 6, //10 items above 1000px browser width
                itemsDesktop : [1000,5], //5 items between 1000px and 901px
                itemsDesktopSmall : [900,3], // betweem 900px and 601px
                itemsTablet: [600,2], //2 items between 600 and 0
                itemsMobile : false, // itemsMobile disabled - inherit from itemsTablet option

                autoPlay: 3000, //Set AutoPlay to 3 seconds
                loop: true,

                margin: 10

            });

            var d = new Date();
            var month = d.getMonth()+1;
            var day = d.getDate();
            var year = d.getFullYear();
            var date = "day"+"month";
            var booking_link_new = "https://cbooking.de/Widgets/parkhotellandau/widget.html";
            var booking_link = "https://www.cbooking.de/RateMonitor/RM.aspx?arrival="+day+"."+month+"."+year+"&departure="+(day+1)+"."+month+"."+year+"&adults=1&rooms=1&client=7538a23a-2e41-47a6-9944-7ca2eac22702&open=1&lang=1&showButton=1&update=1";
            var iframe = "<iframe class=\"booking-frame opened\" height=\"560\" frameborder=\"0\" src=\""+booking_link_new+"\" />";
           /*$('#booking').append(iframe);*/
        });

        // Smooth Scrolling Script by @chriscoyier
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });

        // Scroll to Top Button fading function
        $(document).scroll(function () {
            var scroll = $(this).scrollTop();
            if (scroll > 300) {
                $('.floating-navigation.desktop').fadeIn();
            } else {
                $('.floating-navigation.desktop').fadeOut();
            }
        });

        // Scroll to Top Button fading function
        $(document).scroll(function () {
            var y = $(this).scrollTop();
            if (y > 1000) {
                $('.scroll-top').fadeIn();
            } else {
                $('.scroll-top').fadeOut();
            }
        });


        // Toggle Submenus on TouchScreens (default nav)
        // if ($('html').hasClass('touchevents')) {
        //     var viewport = $(window).width();
        //     if(viewport < 768){
        //         var mainNavparentLink = $('.right-off-canvas-menu li:has(ul) > a');
        //
        //         $(mainNavparentLink).append('<span class="toggle glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span>');
        //
        //         $(mainNavparentLink).click(function(event){event.preventDefault(); $(this).parent().toggleClass('open');});
        //     }else {
        //         $( '.right-off-canvas-menu li:has(ul)' ).doubleTapToGo();
        //     }
        // }

        // Manual-generated WordPress Navi WITH First-Level Landingpage
        if (screen < 768) {
            //Add Toggle Icons to Nav for Dropdown Touch Device Support
            var parentItem = $('.right-off-canvas-menu .menu-item-has-children');
                 $(parentItem).each(function(){
                    $(this).children('a').after('<div class="toggle"><span class="glyphicon glyphicon-chevron-down"></span></div>');
                 });

            $('.right-off-canvas-menu .current-menu-ancestor').addClass('open');
            $('.right-off-canvas-menu .current-menu-parent').addClass('open');
            $('.right-off-canvas-menu .menu-item-has-children.current-menu-item').addClass('open');
        }
        // Prevents the Submenus from stayin' opened after page reloads like it does on phones
        $('.right-off-canvas-menu .menu-item-has-children > .toggle').on('click', function () {
            var element = $(this).parent('li');
            if (element.hasClass('open')) {
                element.removeClass('open');
                //element.find('li').removeClass('open');
            }
            else {
                element.addClass('open');

                //element.siblings('li').removeClass('open');
                //element.siblings('li').find('li').removeClass('open');
            }
        });



        // Scroll to Top Button fading function
        $(document).scroll(function () {
            var vpHeight = $(window).height();
            var y = $(this).scrollTop();
            if (y > vpHeight/2) {
                $('.to-top').removeClass('hidden');
                $('.floaty-bar').removeClass('hidden');
            } else {
                $('.to-top').addClass('hidden');
                $('.floaty-bar').addClass('hidden');
            }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Get Offset of the element which should be sticky an initialize affix
        var viewport = $(window).width();
        if(viewport > 768){

            // Makes the Navbar sticky
            var offsetAffix = $('.main-navigation').offset();
            var offsetHeight = $('.jumbotron').height() + $('.inner-banner').height();
            console.log(offsetHeight);
            $('.main-navigation').affix({
                offset: {
                  top: offsetHeight
                }
            });

            // Initialize Tooltips on specific elements (uses title)
            // $('.tooltip-info a').tooltip({placement: 'bottom'});
        }


        // Toggle function for offcanvas navigation
        $( "#nav-toggle, .exit-off-canvas" ).click(function() {
            $( ".off-canvas-wrap" ).toggleClass( "move-left" );
        });

        // Recalculate offset for sticky element and position for mega dropdown centering on resize
        $( window ).resize(function() {
            var offsetHeight = $('.jumbotron').height() + $('.inner-banner').height();
            var offsetAffix = $('.main-navigation').offset();
            // $('.ubermenu-submenu-type-mega').center();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
